import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import WindowsImg from 'assets/image/Windows.png';
import KestrelProIcon from 'assets/image/KestrelPro.svg';
import { FaArrowUp } from 'react-icons/fa';

const MacWalkThrough = () => {
  const [isVisible, setIsVisible] = useState(false);
  const pagesData = [
    {
      id: 1,
      heading: 'Windows',
      paragraph:
        'The desktop app helps you with real-time insights about your workforce and operations. Download the app to start now.',
      subSections: [
        'Download the Zip file and extract it.',
        'Run the Kestrel Pro applications/Exe File. Allow access to run the Desktop tool.',
        'Login into the tool with Custom Login/ SSO. Select the preassigned project from the list. Add the task to the project.',
        'Select the task for the project.',
        'The tool is all set to use.',
      ],
    },
  ];

  const handleScrollToTop = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  // Add event listener for scrolling
  useEffect(() => {
    topFunction();
    window.addEventListener('scroll', handleScrollToTop);
    return () => {
      window.removeEventListener('scroll', handleScrollToTop);
    };
  }, []);

  return (
    <>
      <div>
        {pagesData.map((page) => (
          <Container
            key={page.id}
            className="walkthrough-pages container-sm bg-white mt-5"
            style={{ maxWidth: '1000px', height: '1000px' }}
          >
            <Row>
              <Col sm={6}>
                <h1 className="blue-color">{page.heading}</h1>
              </Col>
              <Col sm={6} className="text-end">
                <img
                  src={KestrelProIcon}
                  alt="Kestrel Pro Icon"
                  height="68px"
                  style={{
                    marginTop: '-2px',
                    boxShadow: 'none',
                    height: '68px',
                  }}
                />
              </Col>
            </Row>
            {/* <h1 className="blue-color">{page.heading}</h1> */}
            <Row>
              <Col sm={8}>
                <p className="mb-4">{page.paragraph}</p>
                {page.subSections && (
                  <ul>
                    {page.subSections.map((subSection, index) => (
                      <li
                        key={subSection}
                        className="mb-3"
                        style={{ listStyleType: 'decimal' }}
                      >
                        <p className="ms-1">{subSection}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </Col>
              <Col sm={4}>
                <img
                  src={WindowsImg}
                  alt="Kestrel Pro Icon"
                  style={{
                    // marginTop: '-2px',
                    boxShadow: 'none',
                    height: '200px',
                    marginTop: '0px',
                    marginLeft: '60px',
                  }}
                />
              </Col>
            </Row>
          </Container>
        ))}
      </div>
      {isVisible && (
        <Button
          onClick={topFunction}
          className="me-3 shadow-lg rounded-circle scroll-to-top-btn"
          id="myBtn"
          title="Go to top"
        >
          <FaArrowUp size={20} color="#0074C9" />
        </Button>
      )}
    </>
  );
};

export default MacWalkThrough;
