/* eslint-disable react-hooks/rules-of-hooks */
// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationJA from './locales/ja/translation.json';

const initialResources = {
  en: {
    translation: translationEN,
  },
  ja: {
    translation: translationJA,
  },
};

const loadLocale = async (locale) => {
  const { default: translation } = await import(
    `./locales/${locale}/translation.json`
  );
  i18n.addResourceBundle(locale, 'translation', translation, true, true);
};

export const initializeI18n = async (locale) => {
  await i18n.use(initReactI18next).init({
    resources: initialResources,
    fallbackLng: 'en',
    lng: locale,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

  if (!initialResources[locale]) {
    await loadLocale(locale);
  }
};
const preferredLanguage = localStorage.getItem('preferredLanguage');

initializeI18n(preferredLanguage || 'en');

export default i18n;
