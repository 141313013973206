/* eslint-disable  */
import React, { useEffect, useState } from 'react';
import { Nav, NavItem, Collapse, NavLink } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import ErrorBoundary from 'components/ErrorBoundary';
import { HELP_SUPPORT_MENU_ITEMS } from './constant';

const HelpSupportSidebar = ({ isOpen }) => {
  const navigate = useNavigate();
  const rolePermission = HELP_SUPPORT_MENU_ITEMS;
  const renderMenuitems = () => {
    return rolePermission?.map((item) => {
      return <NavMenuItem key={item?.pageUrl} {...item} isOpen={isOpen} />;
    });
  };

  const HelpAndSupporthanlder = () => {
    navigate('/help-and-support');
  };

  return (
    <ErrorBoundary>
      <sidebar className="sidebar bg-white" style={{ width: '300px' }}>
        <Nav vertical navbar>
          <p
            className="mb-1 ms-4 fw-semibold cursor-pointer"
            onClick={HelpAndSupporthanlder}
          >
            Help & Support
          </p>
          {renderMenuitems()}
        </Nav>
      </sidebar>
    </ErrorBoundary>
  );
};

const NavMenuItem = (props) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const { pathname } = useLocation();
  const { isParent, menuTitle, pageUrl, subMenu, isOpen } = props;

  useEffect(() => {
    if (pathname === '/help-and-support/windows-walk-through') {
      setSubnav(true);
    }
  }, [pathname]);

  return (
    <NavItem className={isParent && 'nav-item-border'}>
      {!subMenu && (
        <Link
          to={pageUrl}
          className={
            pathname && `/${pageUrl}` === pathname
              ? 'active nav-link  d-grid'
              : 'nav-link d-grid'
          }
        >
          <div className="d-flex navbarhover align-items-center">
            <span className="ms-4 me-auto text-dark">{menuTitle}</span>
            <div className="d-flex ml-auto me-2">
              {subMenu && <MdKeyboardArrowDown className="ms-0" />}
            </div>
          </div>
        </Link>
      )}

      {subMenu && (
        <>
          <NavLink
            className={
              pathname && `/${pageUrl}` === pathname
                ? 'active nav-link  d-grid'
                : 'nav-link d-grid'
            }
            onClick={showSubnav}
          >
            <div
              className="d-flex navbarhover align-items-center"
              style={{ cursor: 'pointer' }}
            >
              <span className=" ms-4 me-auto text-dark">{menuTitle}</span>
              <div className="d-flex ml-auto me-3 pe-1 arrow">
                {subMenu && <MdKeyboardArrowDown className="ms-0 mt-1" />}
              </div>
            </div>
          </NavLink>
          <Collapse isOpen={subnav}>
            <Nav vertical navbar>
              <SubNavItem subMenu={subMenu} isOpen={isOpen} path={pageUrl} />
            </Nav>
          </Collapse>
        </>
      )}
    </NavItem>
  );
};

export const SubNavItem = (props) => {
  const { subMenu, isOpen } = props;

  return <RenderNavItemList subMenu={subMenu} isOpen={isOpen} />;
};

const RenderNavItemList = (props) => {
  const { subMenu, isOpen } = props;
  const { pathname } = useLocation();

  return (
    subMenu &&
    subMenu.map((subItem) => {
      return (
        <NavItem key={subItem.menuTitle}>
          <Link
            className={
              pathname && `/${subItem.pageUrl}` === pathname
                ? 'active nav-link d-grid'
                : 'nav-link d-grid'
            }
            to={subItem.pageUrl}
          >
            <div className="navbarhover d-flex">
              <span className="ms-5">{subItem.menuTitle}</span>
            </div>
          </Link>
        </NavItem>
      );
    })
  );
};

export default HelpSupportSidebar;
