/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Profile from 'components/Header/components/Profile';
import KestrelProIcon from 'assets/image/KestrelPro.svg';
import { Navbar } from 'reactstrap';

const HelpAndSupportHeader = () => {
  return (
    <Navbar light fixed="top" className="p-0 bg-white header">
      <div className="left-side  d-flex align-items-center">
        <span
          className="ms-1 fs-5 fw-bold"
          style={{
            color: '#000000',
          }}
        >
          <img
            src={KestrelProIcon}
            alt="Kestrel Pro Icon"
            height="68px"
            onClick={() => {
              window.open('https://kestrelpro.ai/', '_blank');
            }}
            className="cursor-pointer"
          />
        </span>
      </div>
      <div className="d-flex align-items-center right-side">
        <Profile />
      </div>
    </Navbar>
  );
};

export default HelpAndSupportHeader;
