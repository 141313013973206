import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import appWalkthrough1 from '../../../../assets/image/appWalkthrough1.svg';
import appWalkthrough2 from '../../../../assets/image/appWalkthrough2.svg';
import appWalkthrough3 from '../../../../assets/image/appWalkthrough3.svg';
import appWalkthrough4 from '../../../../assets/image/appWalkthrough4.svg';

const HelpAndSupportMainPage = () => {
  const navigate = useNavigate();

  const applicationWalkthroughHandler = () => {
    navigate('/help-and-support/application-walk-through');
  };

  const desktopWalkthroughHandler = () => {
    navigate('/help-and-support/windows-walk-through');
  };
  const mobileWalkthroughHandler = () => {
    navigate('/help-and-support/mobile-time-tracker-walk-through');
  };
  const faqWalkthroughHandler = () => {
    navigate('/help-and-support/FAQs');
  };
  const helpAndSupportData = [
    {
      id: 1,
      title: 'Application Walkthrough',
      urlValue: 'dev',
      icon: appWalkthrough1,
      onclick: applicationWalkthroughHandler,
    },
    {
      id: 2,
      title: 'Desktop Client Installation Guide',
      urlValue: 'qa',
      icon: appWalkthrough2,
      onclick: desktopWalkthroughHandler,
    },
    {
      id: 3,
      title: 'Mobile Time Tracker Installation Guide',
      urlValue: 'prod',
      icon: appWalkthrough3,
      onclick: mobileWalkthroughHandler,
    },

    {
      id: 4,
      title: 'FAQs',
      urlValue: 'preprod',
      icon: appWalkthrough4,
      onclick: faqWalkthroughHandler,
    },
  ];

  return (
    <div>
      <Container className="container-sm" style={{ maxWidth: '1000px' }}>
        <div className="pt-4 text-center">
          <div className="top-head ">
            <h1 className="fw-semibold">How can we help you?</h1>
            <p style={{ color: '#737373' }} className="f-18 mb-0">
              KestrelPro strategically aligns all departments with real-time
              data aggregation, analytics, and visualization. Access essential
              resources to effectively utilize KestrelPro here.
            </p>
            <div
              className="d-flex justify-content-center align-items-center mx-auto"
              style={{ backgroundColor: '#f5f8fa' }}
            >
              <Container>
                <Row xs={1} sm={2} lg={12} className="p-5">
                  {helpAndSupportData?.map((item) => {
                    return (
                      <Col key={item.id}>
                        <div
                          className="pt-5 column-box position-relative overflow-hidden my-3 mx-1 cursor-pointer rounded justify-content-center align-items-center"
                          style={{ height: '200px' }}
                          onClick={item.onclick}
                        >
                          <div>
                            <img
                              src={item.icon}
                              alt="logisConditionalMenuo"
                              className="mb-2"
                            />
                          </div>
                          <h1
                            id="h1Title"
                            className="mb-0 f-30 position-relative f-18 text-center p-3"
                            style={{ zIndex: 2 }}
                          >
                            {item.title}
                          </h1>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HelpAndSupportMainPage;
