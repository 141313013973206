import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { ApolloProvider } from '@apollo/client';
import AuthProvider from 'contexts/AuthContext/AuthContext';
import AppTitle from 'contexts/AppTitle';
import ErrorBoundary from 'components/ErrorBoundary';
import App from './App';
import i18n from './i18n';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import 'react-virtualized/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import './style/main.scss';
import 'rc-time-picker/assets/index.css';
import client from './config/apollo';
import PreferredLanguage from 'contexts/PreferredLanguage';
import IndustrySector from 'contexts/IndustrySector';

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <ErrorBoundary>
      <React.StrictMode>
        <BrowserRouter>
          <ToastContainer
            autoClose={5000}
            position="top-right"
            className="f-14"
          />
          <AlertProvider template={AlertTemplate} {...options}>
            <ApolloProvider client={client}>
              <Provider store={store}>
                <ErrorBoundary>
                  <AppTitle>
                    <I18nextProvider i18n={i18n}>
                      <PreferredLanguage>
                        <IndustrySector>
                          <App />
                        </IndustrySector>
                      </PreferredLanguage>
                    </I18nextProvider>
                  </AppTitle>
                </ErrorBoundary>
              </Provider>
            </ApolloProvider>
          </AlertProvider>
        </BrowserRouter>
      </React.StrictMode>
    </ErrorBoundary>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
