/* eslint-disable radix */
/* eslint-disable no-bitwise */
import React from 'react';
import { BiCaretDown, BiCaretUp } from 'react-icons/bi';

export const compareMonth = (f1, f2) => {
  const getDate = (f) => {
    const d = f.split('-');
    d[0] =
      {
        jan: 1,
        feb: 2,
        mar: 3,
        apr: 4,
        may: 5,
        jun: 6,
        jul: 7,
        aug: 8,
        sep: 9,
        oct: 10,
        nov: 11,
        dec: 12,
      }[d[0].toLowerCase()] || 0;
    return d;
  };

  const d1 = getDate(f1);
  const d2 = getDate(f2);
  return d1[1] > d2[1] || (d1[1] === d2[1] && d1[0] > d2[0]);
};

const parseDate = (input) => {
  // Transform date from text to date
  const parts = input.match(/(\d+)/g);
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
};

export const workingDaysBetweenDates = (d0, d1) => {
  const startDate = parseDate(d0);
  const endDate = parseDate(d1);

  if (d0 === d1) {
    return 1;
  }

  // Validate input
  if (endDate <= startDate) {
    return 0;
  }

  // Calculate days between dates
  const millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  const diff = endDate - startDate; // Milliseconds between datetime objects
  let days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  const weeks = Math.floor(days / 7);
  days -= weeks * 2;

  // Handle special cases
  const startDay = startDate.getDay();
  const endDay = endDate.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) {
    days -= 2;
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay === 0 && endDay !== 6) {
    days--;
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay === 6 && startDay !== 0) {
    days--;
  }
  return days;
};

const hmsToSeconds = (s) => {
  const b = s.split(':');
  return b[0] * 3600 + b[1] * 60 + (+b[2] || 0);
};

const secondsToHMS = (secs) => {
  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }
  let updatedSec = secs;
  const sign = updatedSec < 0 ? '-' : '';
  updatedSec = Math.abs(updatedSec);
  return `${Math.trunc(sign + z(updatedSec / 3600 || 0))}:${z(
    Math.trunc((updatedSec % 3600) / 60 || 0)
  )}`;
};

export const getTimeDifference = (firstTime, secondTime) => {
  return secondsToHMS(
    hmsToSeconds(`${firstTime}:00`) -
      hmsToSeconds(secondTime === 0 ? '00:00' : secondTime)
  );
};

export const addTimes = (startTime, endTime) => {
  const times = [0, 0, 0];
  const max = times.length;

  const a = (startTime || '').split(':');
  const b = (endTime || '').split(':');

  // normalize time values
  for (let i = 0; i < max; i++) {
    a[i] = Number.isNaN(parseInt(a[i], 10)) ? 0 : parseInt(a[i], 10);
    b[i] = Number.isNaN(parseInt(b[i], 10)) ? 0 : parseInt(b[i], 10);
  }

  for (let i = 0; i < max; i++) {
    times[i] = a[i] + b[i];
  }

  let hours = times[0];
  let minutes = times[1];
  let seconds = times[2];

  if (seconds >= 60) {
    const m = (seconds / 60) << 0;
    minutes += m;
    seconds -= 60 * m;
  }

  if (minutes >= 60) {
    const h = (minutes / 60) << 0;
    hours += h;
    minutes -= 60 * h;
  }

  const formattedTime = `${Number(`0${hours}`)}:${`0${minutes}`.slice(
    -2
  )}:${`0${seconds}`.slice(-2)}`;

  return formattedTime.slice(0, -3);
};

export const addTimesTimesheetSummary = (billableHrs, nonBillableHrs) => {
  const billableParts = billableHrs.split(':');
  const nonBillableParts = nonBillableHrs.split(':');

  // Parse the hours and minutes as integers
  const billableHours = parseInt(billableParts[0]);
  const billableMinutes = parseInt(billableParts[1]);
  const nonBillableHours = parseInt(nonBillableParts[0]);
  const nonBillableMinutes = parseInt(nonBillableParts[1]);

  // Calculate the total hours, minutes, and seconds
  const totalHours = billableHours + nonBillableHours;
  const totalMinutes = billableMinutes + nonBillableMinutes;

  // Adjust for overflow minutes (more than 60 minutes)
  const adjustedHours = totalHours + Math.floor(totalMinutes / 60);
  const adjustedMinutes = totalMinutes % 60;

  // Format hours and minutes as two-digit numbers
  const formattedHours = String(adjustedHours).padStart(2, '0');
  const formattedMinutes = String(adjustedMinutes).padStart(2, '0');

  return {
    hours: formattedHours,
    minutes: formattedMinutes,
    seconds: 0, // Since seconds were not provided, set them to 0
  };
};

export const substractTimes = (startTime, endTime) => {
  const strToMins = (t) => {
    const s = t.split(':');
    return Number(s[0]) * 60 + Number(s[1]);
  };

  const minsToStr = (t) => {
    return `${Math.trunc(t / 60)}:${`00${t % 60}`.slice(-2)}`;
  };

  const result = minsToStr(strToMins(startTime) - strToMins(endTime));
  return result;
};

export const getCRUDPermissions = (permissions, pathname) => {
  const filterPermission = permissions.find((permission) => {
    return permission.menu_items.pageUrl === pathname;
  });
  return filterPermission;
};

export const getFieldPermissions = (permissions, pathname) => {
  const filterPermission = permissions.filter((permission) => {
    return permission.formFields.forms.form === pathname;
  });
  return filterPermission;
};

export const columnHeaderTitle = (
  columnType,
  columnTitle,
  sortDirection,
  sortBy,
  handleSortColumn
) => {
  return (
    <div
      onClick={() =>
        handleSortColumn(columnType, sortDirection === 'asc' ? 'desc' : 'asc')
      }
    >
      <span>{columnTitle}</span>
      {sortBy === columnType && sortDirection === 'asc' ? <BiCaretDown /> : ''}
      {sortBy === columnType && sortDirection === 'desc' ? <BiCaretUp /> : ''}
    </div>
  );
};

const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
};

export const checkDate = (detail) => {
  const today = new Date();
  const endDate = new Date(detail.actualAssignmentEndDate);
  return dateInPast(endDate, today);
};

export const isViewField = (type, permissionType, modulesfieldPermissions) => {
  const checkPermission = modulesfieldPermissions.find((permission) => {
    return permission.formFields.fields === type;
  });
  if (checkPermission && checkPermission.id) {
    return checkPermission[permissionType];
  }

  return true;
};

const isWeekday = (year, month, day) => {
  const d = new Date(year, month, day).getDay();
  return d !== 0 && d !== 6;
};
export const getWeekdaysInMonth = (month, year) => {
  const days = new Date(year, month, 0).getDate();
  let weekdays = 0;
  for (let i = 0; i < days; i++) {
    if (isWeekday(year, month - 1, i + 1)) weekdays++;
  }
  return weekdays;
};

// plus icon
export const plusIconSrc =
  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus-square'%3E%3Cpath d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z'/%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E";
// minus icon
export const minusIconSrc =
  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash-square'%3E%3Cpath d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z'/%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E";
