import React, { useContext, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { FaUserCircle } from 'react-icons/fa';
import { LiaIndustrySolid } from 'react-icons/lia';
import { RiLogoutCircleRFill } from 'react-icons/ri';
import { FaArrowRightFromBracket } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { authContext } from 'contexts/AuthContext/AuthContext';
import { deleteToken } from 'utils/UserDetail';
import { CLIENT_ADMIN } from 'constants/constants';
import { userLogout } from 'pages/Login/LoginActionTypes';
import ErrorBoundary from 'components/ErrorBoundary';
import ToolDownload from './ToolDownload';
import { BsArrowUpRightSquare } from 'react-icons/bs';
import { MdLanguage } from 'react-icons/md';

import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'LanguageSwitcher';
import IndustrySector from 'components/IndustrySector';

const Profile = ({
  direction,
  showUserLangData,
  setShowUserLangData,
  ...args
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [industrySectorIsOpen, setIndustrySectorIsOpen] = useState(false);
  const currentUrl = window.location.href;
  const splitUrl = currentUrl.split('.');
  const splitBaseUrl = splitUrl[0].split('/');
  const subDomain = splitBaseUrl && splitBaseUrl.length ? splitBaseUrl[2] : '';

  const { auth } = useContext(authContext);

  const detail = auth?.data;

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const openModal = () => {
    setModalIsOpen(!modalIsOpen);
  };
  const openIndustrySector = () => {
    setIndustrySectorIsOpen(!industrySectorIsOpen);
  };

  const logoutUser = async () => {
    await dispatch(userLogout({}));
    await deleteToken();
    localStorage.setItem('email', null);
    localStorage.setItem('termsAndConditions', null);
    navigate('/login');
  };
  const [adminValue, setAdminValue] = useState('');

  const handleButtonClick = (button) => {
    setAdminValue(button);
    localStorage.setItem('userType', 'admin');
    navigate(0);
  };
  const handleUserButtonClick = (button) => {
    setAdminValue(button);
    localStorage.setItem('userType', 'user');
    navigate(0);
  };

  const userType = localStorage.getItem('userType');
  const { t } = useTranslation();
  return (
    <ErrorBoundary>
      <Dropdown
        className="profile"
        isOpen={dropdownOpen}
        toggle={toggle}
        direction={direction}
      >
        <DropdownToggle className="p-0 bg-transparent border-0 shadow-none">
          <FaUserCircle size={30} color="#666" />
        </DropdownToggle>
        <DropdownMenu {...args}>
          <div className="text-left ms-3 d-flex">
            <div className="mt-1">
              <FaUserCircle size={50} color="#666" />
            </div>

            <div>
              <span className="fw-medium d-block  f-16 mt-2 ms-2">
                {detail.employeeName}
              </span>
              <span className="d-block f-14 ms-2">{detail.appRoleTitle}</span>
            </div>
          </div>
          <div className="text-left ms-3">
            {detail && (
              <>
                {}
                {/* <div className="mb-3">
                  {userType === 'admin' ? (
                    <Button
                      color="link"
                      className="p-0 text-decoration-none fw-normal f-14"
                      style={{
                        color: '#c34204',
                      }}
                      onClick={(e) => handleUserButtonClick('chapter', e)}
                    >
                      User Panel
                    </Button>
                  ) : 
                  (
                    (detail?.appRoleType === CLIENT_ADMIN ||
                      userType === 'user') && (
                      <Button
                        color="link"
                        className="p-0 text-decoration-none fw-normal f-14"
                        style={{
                          color: '#c34204',
                        }}
                        onClick={(e) => handleButtonClick('chapter', e)}
                      >
                        Admin Panel
                      </Button>
                    )
                  )}
                </div> */}
              </>
            )}
            {subDomain === '47billion' ? <ToolDownload /> : ''}

            <Button
              color="link"
              className="p-0 text-decoration-none fw-normal f-14  mt-2 tooldownload-button"
              style={{
                color: 'black',
              }}
              onClick={() => {
                window.open(
                  'https://kestrelpro.ai/terms-and-conditions/',
                  '_blank'
                );
              }}
            >
              <BsArrowUpRightSquare size="11" />
              <span className="ms-2">{t('Terms & Conditions')}</span>
            </Button>
            {subDomain === 'demo-it-product' ||
            subDomain === 'demo-it-services' ||
            subDomain === 'demo-media' ? (
              <Button
                color="link"
                className="p-0 text-decoration-none fw-normal f-14 mb-2 mt-2 tooldownload-button"
                onClick={openModal}
              >
                <MdLanguage size="14" />
                <span className="ms-2">{t('Select Language')}</span>
              </Button>
            ) : (
              ''
            )}
            {subDomain === 'demo' ? (
              <Button
                color="link"
                className="p-0 text-decoration-none fw-normal f-14 mb-3  tooldownload-button"
                onClick={openIndustrySector}
              >
                <LiaIndustrySolid size="16" />
                <span className="ms-2">{t('Industry Sector')}</span>
              </Button>
            ) : (
              ''
            )}
          </div>

          <DropdownItem onClick={logoutUser}>
            <div className="text-left ms-3">
              <FaArrowRightFromBracket /> {t('Log Out')}
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {modalIsOpen && (
        <LanguageSwitcher
          setDropdownOpen={setDropdownOpen}
          dropdownOpen={dropdownOpen}
          openModal={openModal}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          showUserLangData={showUserLangData}
          setShowUserLangData={setShowUserLangData}
        />
      )}
      {industrySectorIsOpen && (
        <IndustrySector
          setDropdownOpen={setDropdownOpen}
          dropdownOpen={dropdownOpen}
          openModal={openIndustrySector}
          modalIsOpen={industrySectorIsOpen}
          setModalIsOpen={setIndustrySectorIsOpen}
          showUserLangData={showUserLangData}
          setShowUserLangData={setShowUserLangData}
        />
      )}
    </ErrorBoundary>
  );
};

export default Profile;
