import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import KestrelProIcon from 'assets/image/KestrelPro.svg';
import LinuxImg from 'assets/image/Linux.png';
import { FaArrowUp } from 'react-icons/fa';

const LinuxWalkThrough = () => {
  const [isVisible, setIsVisible] = useState(false);

  const pagesData = [
    {
      id: 1,
      heading: 'Linux',
      paragraph: '',
      subSections: [
        'Download the .deb file.',
        'Run the .deb file.',
        'Install the Kestrel Pro application.',
        'Login into the tool with Custom Login/SSO. Select the preassigned project from the list. Add the task to the project.',
        'Select the task, and the tool is all set to use.',
      ],
      note: 'If you are a Linux 22 user, then you have to install LightDM using the command – ‘sudo apt install lightdm’',
      additionalInstructions:
        'You will be prompted with multiple choices, where you can proceed further by choosing LightDM from the options. Then, you are required to restart the system.',
    },
  ];

  const handleScrollToTop = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  // Add event listener for scrolling
  useEffect(() => {
    topFunction();
    window.addEventListener('scroll', handleScrollToTop);
    return () => {
      window.removeEventListener('scroll', handleScrollToTop);
    };
  }, []);

  return (
    <>
      <div>
        {pagesData.map((page) => (
          <Container
            key={page.id}
            className="walkthrough-pages container-sm bg-white mt-5"
            style={{ maxWidth: '1000px', height: '1000px' }}
          >
            <Row>
              <Col sm={6}>
                <h1 className="blue-color">{page.heading}</h1>
              </Col>
              <Col sm={6} className="text-end">
                <img
                  src={KestrelProIcon}
                  alt="Kestrel Pro Icon"
                  height="68px"
                  style={{
                    marginTop: '-2px',
                    boxShadow: 'none',
                    height: '68px',
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={8}>
                <p>{page.paragraph}</p>

                {page.subSections && (
                  <ul>
                    {page.subSections.map((subSection, index) => (
                      <li key={subSection} style={{ listStyleType: 'decimal' }}>
                        <p className="ms-1">{subSection}</p>
                      </li>
                    ))}
                  </ul>
                )}
                <p>
                  <b>Note - </b>
                  {page.note}
                </p>
                <p>{page.additionalInstructions}</p>
              </Col>
              <Col sm={4} className="text-end">
                <img
                  src={LinuxImg}
                  alt="Kestrel Pro Icon"
                  style={{
                    boxShadow: 'none',
                    height: '200px',
                    marginTop: '0px',
                    // marginLeft: '60px',
                  }}
                />
              </Col>
            </Row>
          </Container>
        ))}
      </div>
      {isVisible && (
        <Button
          onClick={topFunction}
          className="me-3 shadow-lg rounded-circle scroll-to-top-btn"
          id="myBtn"
          title="Go to top"
        >
          <FaArrowUp size={20} color="#0074C9" />
        </Button>
      )}
    </>
  );
};

export default LinuxWalkThrough;
