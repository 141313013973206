import React, { useEffect } from 'react';
import HelpSupportSidebar from './components/HelpSupportSidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import HelpAndSupportHeader from './components/HelpAndSupportHeader';
import { USER_DATA } from 'constants/constants';
import Login from 'pages/Login';

const HelpAndSupport = () => {
  const userData = JSON.parse(localStorage.getItem(USER_DATA));

  const navigate = useNavigate();
  useEffect(() => {
    if (!userData) {
      navigate('/login');
    }
  }, []);
  return (
    <div
      className="inner-page help-and-support"
      style={{ backgroundColor: '#F5F8FA' }}
    >
      {!userData ? <Login /> : <HelpAndSupportHeader />}

      <div>
        <HelpSupportSidebar />
        <main className="main-content">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default HelpAndSupport;
