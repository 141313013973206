import { gql } from '@apollo/client';

export const GET_EMP_TOKEN = gql`
  query getEmpToken($email: String!) {
    getEmpToken(email: $email)
  }
`;

export const CUSTOM_LOGIN = gql`
  mutation customLogin(
    $email: String!
    $password: String!
    $organizationId: Int!
  ) {
    customLogin(
      email: $email
      password: $password
      organizationId: $organizationId
    )
  }
`;
