export const HELP_SUPPORT_MENU_ITEMS = [
  {
    menuUniqueId: 1,
    menuTitle: 'Application Walkthrough',
    menuIconName: 'RiDashboardLine',
    isParent: false,
    pageUrl: 'help-and-support/application-walk-through',
    menuSequence: '1',
    parentId: null,
  },
  {
    menuUniqueId: 4,
    menuTitle: 'Desktop Client Installation Guide',
    menuIconName: 'VscCalendar',
    isParent: true,
    pageUrl: '',
    menuSequence: '5',
    parentId: null,
    subMenu: [
      {
        menuUniqueId: 6,
        menuTitle: 'Windows',
        menuIconName: 'IoPeopleSharp',
        isParent: false,
        pageUrl: 'help-and-support/windows-walk-through',
        menuSequence: '5.2',
        parentId: 4,
      },
      {
        menuUniqueId: 5,
        menuTitle: 'Mac',
        menuIconName: 'BiSpreadsheet',
        isParent: false,
        pageUrl: 'help-and-support/mac-walk-through',
        menuSequence: '5.1',
        parentId: 4,
      },
      {
        menuUniqueId: 6,
        menuTitle: 'Linux',
        menuIconName: 'IoPeopleSharp',
        isParent: false,
        pageUrl: 'help-and-support/linux-walk-through',
        menuSequence: '5.2',
        parentId: 4,
      },
    ],
  },
  {
    menuUniqueId: 2,
    menuTitle: 'Mobile Time Tracker Installation Guide',
    menuIconName: 'IoIosPeople',
    isParent: false,
    pageUrl: 'help-and-support/mobile-time-tracker-walk-through',
    menuSequence: '2',
    parentId: null,
  },
  {
    menuUniqueId: 24,
    menuTitle: 'FAQs',
    menuIconName: 'TeamsList',
    isParent: false,
    pageUrl: 'help-and-support/FAQs',
    menuSequence: '3',
    parentId: null,
  },
];
