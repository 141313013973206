/* eslint-disable react/jsx-no-useless-fragment */
import { useMutation } from '@apollo/client';
import React, { createContext, useContext, useState } from 'react';
import { authContext } from 'contexts/AuthContext/AuthContext';
import { INDUSTRY_SECTOR_SWITCHER } from 'contexts/AppDetailsGraphQL';
import { USER_DATA, USER_TOKEN } from 'constants/constants';
import Loader from 'components/Loader';

export const MutationContext = createContext();

const IndustrySector = ({ children }) => {
  const [industrySectorState, setIndustrySectorState] = useState();

  const { auth } = useContext(authContext);

  const userDataIndustrySector =
    auth?.data?.data?.industrySector || auth?.data?.industrySector;
  const [switchIndustrySector, { error, loading, data }] = useMutation(
    INDUSTRY_SECTOR_SWITCHER
  );

  const performITMutation = async (preferredIndustrySector) => {
    setIndustrySectorState(preferredIndustrySector);
    try {
      const { data } = await switchIndustrySector({
        variables: {
          industrySector: preferredIndustrySector,
        },
      });

      <Loader />;
      window.location.reload();
      localStorage.setItem('industrySector', preferredIndustrySector);
      //   localStorage.setItem(USER_TOKEN, data?.switchLanguage?.token);
      const userData = JSON.parse(localStorage.getItem(USER_DATA)) || {};

      //   userData.token = data?.switchLanguage?.token;
      userData.industrySector =
        data?.switchIndustrySector?.industrySector || userDataIndustrySector;

      localStorage.setItem(USER_DATA, JSON.stringify(userData));
    } catch (err) {
      console.error('Error switching language:', error);
    }
  };

  return (
    <MutationContext.Provider
      value={{
        error,
        loading,
        performITMutation,
        data,
        setIndustrySectorState,
        // industrySectorState,
      }}
    >
      {children}
    </MutationContext.Provider>
  );
};
export default IndustrySector;
export const useMutationContext = () => useContext(MutationContext);
