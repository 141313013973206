import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from 'utils/UserDetail';

const verifyToken = () => {
  const authToken = getToken();
  return authToken;
};

// eslint-disable-next-line react/display-name
const withAuth = (Component, type) => (props) => {
  if (type === 'login') {
    return verifyToken() ? (
      <Navigate to="/dashboard" />
    ) : (
      <Component {...props} />
    );
  }
  return verifyToken() ? <Component {...props} /> : <Navigate to="/login" />;
};

export default withAuth;
