import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { minusIconSrc, plusIconSrc } from 'utils/helper';
import { FAQ_DATA } from '../commonDataArray';
import KestrelProIcon from 'assets/image/KestrelPro.svg';

const FAQs = () => {
  const [toggle, setToggle] = useState(null);
  const handleToggle = (id) => {
    if (toggle === id) {
      setToggle(null);
      return false;
    }
    setToggle(id);
  };

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    topFunction();
  }, []);

  return (
    <div>
      {FAQ_DATA.map((page) => (
        <Container
          key={page.id}
          className="walkthrough-pages container-sm bg-white mt-5 position-relative"
          style={{ maxWidth: '1000px', height: '1000px' }}
        >
          <Row>
            <Col sm={6}>
              <h1 className="blue-color">{page.heading}</h1>
            </Col>
            <Col sm={6} className="text-end">
              <img
                src={KestrelProIcon}
                alt="Kestrel Pro Icon"
                height="68px"
                style={{
                  marginTop: '-2px',
                  boxShadow: 'none',
                  height: '68px',
                }}
              />
            </Col>
          </Row>
          {page.faqs.map((faq) => (
            <div
              className="mb-4 f-18 shadow-sm p-2 rounded"
              style={{
                alignItems: 'center',
              }}
              key={faq}
            >
              <h5>
                <Button
                  color="link"
                  className="grey-6a6 text-decoration-none me-2 p-0"
                  onClick={() => handleToggle(faq.id)}
                >
                  {faq.id === toggle ? (
                    <img
                      width="13"
                      src={minusIconSrc}
                      alt="minus-icon"
                      className="m-0"
                    />
                  ) : (
                    <img
                      width="13"
                      src={plusIconSrc}
                      alt="minus-icon"
                      className="m-0"
                    />
                  )}
                </Button>{' '}
                {faq.question}
              </h5>
              {faq.id === toggle && (
                <>
                  <p style={{ color: '#737373' }} className="ms-4 mb-3">
                    {faq.answer}

                    {faq.question ===
                    'Is there a free trial available for KestrelPro?' ? (
                      <a
                        href="https://kestrelpro.ai/request-a-demo/"
                        target="_blank"
                        rel="noreferrer"
                        className="ms-2"
                      >
                        https://kestrelpro.ai/request-a-demo/
                      </a>
                    ) : (
                      ''
                    )}
                  </p>
                </>
              )}
            </div>
          ))}
        </Container>
      ))}
    </div>
  );
};

export default FAQs;
