import React, { useContext } from 'react';
import { Formik } from 'formik';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import WindowsLineIcon from 'remixicon-react/WindowsLineIcon';
import { AppTitleContext } from 'contexts/AppTitle';
import KestrelProIcon from 'assets/image/KestrelPro.svg';
import ShapeImg from 'assets/image/shape.png';
import LoginBG from 'assets/image/login-bg.png';
import withAuth from 'hoc/withAuth';
import { setUserDetail, setToken } from 'utils/UserDetail';
import { authContext } from 'contexts/AuthContext/AuthContext';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import LoginForm from './components/LoginForm';
import { CUSTOM_LOGIN } from './LoginGraphql';
import validationSchema from './components/LoginForm/validationSchema';

const Login = () => {
  const { setAuthData, auth } = useContext(authContext);

  const currentUrl = window.location.href;
  const splitUrl = currentUrl.split('.');
  const splitBaseUrl = splitUrl[0].split('/');
  const data = useContext(AppTitleContext);
  const navigate = useNavigate();
  const [customLogin] = useMutation(CUSTOM_LOGIN, {
    onCompleted: (onCustomLogin) => {
      if (onCustomLogin) {
        const {
          customLogin: { data, info },
        } = onCustomLogin;
        // toast.success(info);
        setAuthData(data);
        setUserDetail(data);

        setToken(data.token);

        navigate('/dashboard');
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  // const {
  //   getOrganizationDetails: { toolName, loginType, id },
  // } = data;
  const loginTypeDetails = data?.getOrganizationDetails?.loginType;
  const id = data?.getOrganizationDetails?.id;
  const initialValues = { email: '', password: '', organizationId: '' };

  const handleManualLogin = (values) => {
    try {
      customLogin({
        variables: {
          ...values,
          organizationId: id,
        },
      });
    } catch (error) {}
  };

  return (
    <div className="no-auth-main-container auth-page">
      <Row className="mx-0">
        <Col sm={6} className="px-0 d-none d-md-block">
          <div className="left-wrap position-relative">
            <img
              alt="login-shape"
              src={ShapeImg}
              className="img-fluid shape-img"
            />
            <img alt="login-bg" src={LoginBG} className="img-fluid bgimg" />
          </div>
        </Col>
        <Col md={6} className="px-0">
          <div className="right-wrap position-relative">
            <Card className="border-0 form-card mx-auto">
              <CardBody className="p-0">
                <CardTitle className="d-flex">
                  <img
                    src={KestrelProIcon}
                    alt="logo"
                    style={{ height: '130px' }}
                  />
                </CardTitle>
              </CardBody>

              <CardBody className="p-0">
                {loginTypeDetails === 'Manual' && (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleManualLogin(values);
                    }}
                    children={(props) => {
                      return <LoginForm {...props} />;
                    }}
                  />
                )}
                {loginTypeDetails === 'Microsoft' && (
                  <a
                    className="btn btn-light d-block"
                    href={`${
                      process.env.REACT_APP_BACKEND_BASE_URL
                    }/api/auth/microsoft?redirect=${
                      splitBaseUrl && splitBaseUrl.length ? splitBaseUrl[2] : ''
                    }`}
                  >
                    <WindowsLineIcon size="20" className="me-2" /> Sign In with
                    Microsoft
                  </a>
                )}
                {loginTypeDetails === 'Google' && (
                  <a
                    className="btn btn-light d-block"
                    href={`${
                      process.env.REACT_APP_BACKEND_BASE_URL
                    }/api/auth/google?redirect=${
                      splitBaseUrl && splitBaseUrl.length ? splitBaseUrl[2] : ''
                    }`}
                  >
                    <WindowsLineIcon size="20" className="me-2" /> Sign In with
                    Google
                  </a>
                )}
                <div className="ortext position-relative">
                  <span />
                </div>
              </CardBody>
            </Card>
            {/* <div className="bottom-text text-center">
              <span className="text-decoration-underline">
                Having trouble signing in ?
              </span>
            </div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withAuth(Login, 'login');
