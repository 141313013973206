import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { initializeI18n } from '../i18n';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { useMutationContext } from 'contexts/PreferredLanguage';
import { USER_DATA } from 'constants/constants';

const LanguageSwitcher = ({
  modalIsOpen,
  openModal,
  setModalIsOpen,

  setShowUserLangData,
}) => {
  const { t } = useTranslation();
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const lang = JSON.parse(localStorage.getItem(USER_DATA)) || {};

  const { performMutation, setlangState } = useMutationContext();

  const [selectedLanguage, setSelectedLanguage, langState] = useState(
    lang?.preferredLanguage
  );

  useEffect(() => {
    if (lang?.preferredLanguage) {
      initializeI18n(lang?.preferredLanguage);
      setSelectedLanguage(lang?.preferredLanguage);
    }
  }, [lang?.preferredLanguage]);

  const changeLanguage = async (lng) => {
    await initializeI18n(lng);
    setSelectedLanguage(lng);
    setShowUserLangData(lng);
    setlangState(lng);
  };

  const handleLanguageChange = (selectedOption) => {
    changeLanguage(selectedOption?.value);
    performMutation(selectedOption?.value);
  };

  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'ja', label: 'Japanese' },
  ];

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        toggle={openModal}
        centered
        style={{ height: '70vh !important', width: '30%' }}
      >
        <ModalHeader toggle={closeModal}>{t('Select Language')}</ModalHeader>
        <ModalBody style={{ textAlign: 'center', paddingBottom: '50px' }}>
          <Select
            classNamePrefix="react-select"
            value={languageOptions.find(
              (option) => option.value === selectedLanguage
            )}
            onChange={handleLanguageChange}
            options={languageOptions}
            defaultValue={languageOptions.find(
              (option) => option.value === langState || lang?.preferredLanguage
            )}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LanguageSwitcher;
