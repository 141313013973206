import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import KestrelProIcon from 'assets/image/KestrelPro.svg';
import AndroidImg from 'assets/image/Android-Logo-New.png';
import IOSImg from 'assets/image/iOS.png';
import { FaArrowUp } from 'react-icons/fa';

const pagesData = [
  {
    id: 1,
    heading: 'Mobile Time Tracker',
    paragraph: '',
    subSections: [
      'Download the application from the app store.',
      'Install the application on your mobile.',
      'Fill in the required credentials, which are your email ID and password.',
      'You can now see the KestrelPro dashboard.',
      'Select the assigned Project.',
      'Select the relevant task.',
      'Start the timer using the "Play and Pause" button.',
      'You can change the Project and Task from the dropdown.',
      'Refresh the data in the application by clicking on the "Refresh" button placed next to the Project list.',
      'You can Sync the time by clicking on the "Sync Time" option available in the menu, which can be used by clicking the three dots at the bottom right corner.',
      'Clear the cache by clicking on the "Clear Cache" option available in the menu, which can be used by clicking the three dots at the bottom right corner.',
      'Log out of the application by clicking on "Log Out" option available in the menu, which can be used by clicking the three dots at the bottom right corner.',
    ],
  },
];

const MobileToolWalkThrough = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollToTop = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  // Add event listener for scrolling
  useEffect(() => {
    topFunction();
    window.addEventListener('scroll', handleScrollToTop);
    return () => {
      window.removeEventListener('scroll', handleScrollToTop);
    };
  }, []);
  return (
    <>
      <div>
        {pagesData.map((page) => (
          <Container
            key={page.id}
            className="walkthrough-pages container-sm bg-white mt-5"
            style={{ maxWidth: '1000px', height: '1000px' }}
          >
            <Row>
              <Col sm={6}>
                <h1 className="blue-color">{page.heading}</h1>
              </Col>
              <Col sm={6} className="text-end">
                <img
                  src={KestrelProIcon}
                  alt="Kestrel Pro Icon"
                  height="68px"
                  style={{
                    marginTop: '-2px',
                    boxShadow: 'none',
                    height: '68px',
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={8}>
                <p>{page.paragraph}</p>
                {page.imgSrc && (
                  <div className="left-wrap position-relative">
                    <img
                      alt={`${page.heading
                        .toLowerCase()
                        .replace(' ', '-')}-shape`}
                      src={page.imgSrc}
                      className="img-fluid shape-img"
                    />
                  </div>
                )}
                {page.subSections && (
                  <ul>
                    {page.subSections.map((subSection) => (
                      <li key={subSection} style={{ listStyleType: 'decimal' }}>
                        <p className="ms-1">{subSection}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </Col>

              <Col sm={4} className="text-end">
                <img
                  src={AndroidImg}
                  alt="Kestrel Pro Icon"
                  style={{
                    boxShadow: 'none',
                    height: '150px',
                    marginTop: '35px',
                    // marginLeft: '60px',
                  }}
                />

                <img
                  src={IOSImg}
                  alt="Kestrel Pro Icon"
                  style={{
                    boxShadow: 'none',
                    height: '200px',
                    alignItems: 'center',
                    // marginTop: '-30px',
                    marginRight: '30px',
                  }}
                />
              </Col>
            </Row>
          </Container>
        ))}
      </div>
      {isVisible && (
        <Button
          onClick={topFunction}
          className="me-3 shadow-lg rounded-circle scroll-to-top-btn"
          id="myBtn"
          title="Go to top"
        >
          <FaArrowUp size={20} color="#0074C9" />
        </Button>
      )}
    </>
  );
};

export default MobileToolWalkThrough;
