/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { AiOutlineDownload } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

const ToolDownload = () => {
  const { t } = useTranslation();
  const [os, setOS] = useState('');
  const [architecture, setArchitecture] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('win')) {
      setOS('Windows');
    } else if (userAgent.includes('mac')) {
      setOS('Macintosh');
      if (userAgent.includes('intel')) {
        setArchitecture('Intel');
      } else if (userAgent.includes('arm')) {
        setArchitecture('M1');
      } else {
        setArchitecture('Unknown');
      }
    } else if (userAgent.includes('linux')) {
      setOS('Linux');
    } else {
      setOS('Unknown');
    }
  }, []);

  const handleDownload = () => {
    if (os === 'Windows') {
      window.location.href =
        'https://47kestrel-tool.kestrelpro.ai/windows/KestrelPro_4.1.zip';
    } else if (os === 'Macintosh') {
      if (architecture === 'Intel') {
        window.location.href =
          'https://47kestrel-tool.kestrelpro.ai/macintel/KestrelPro_4.1.dmg';
      } else if (architecture === 'M1') {
        window.location.href =
          'https://47kestrel-tool.kestrelpro.ai/macm1/KestrelPro_4.1.zip';
      } else {
        alert('File download not supported for this Mac architecture.');
      }
    } else if (os === 'Linux') {
      window.location.href =
        'https://47kestrel-tool.kestrelpro.ai/linux/KestrelPro_4.1.deb';
    } else {
      alert('File download not supported for this operating system.');
    }
  };

  return (
    <div className="mt-2">
      {/* <h1>Operating System: {os}</h1> */}
      {os === 'Windows' || os === 'Macintosh' || os === 'Linux' ? (
        <Button
          color="black"
          className="p-0 text-decoration-none fw-normal f-14 tooldownload-button"
          onClick={handleDownload}
        >
          <AiOutlineDownload size="14" title="Download Tool" />
          <span className="ms-2">{t('Desktop Tool 4.1')}</span>
        </Button>
      ) : (
        <p>{t('File download not supported for this operating system.')}</p>
      )}
    </div>
  );
};

export default ToolDownload;
