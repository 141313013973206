import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import {
  FEATURES_PAGE_DATA,
  FEATURE_GUIDE_PAGE_DATA,
  SNAPSHOT_PAGE_DATA,
} from '../commonDataArray';
import { FaArrowUp } from 'react-icons/fa';
import KestrelProIcon from 'assets/image/KestrelPro.svg';

const UserPanelWalkThrough = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScrollToTop = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  // Add event listener for scrolling
  useEffect(() => {
    topFunction();
    window.addEventListener('scroll', handleScrollToTop);
    return () => {
      window.removeEventListener('scroll', handleScrollToTop);
    };
  }, []);

  return (
    <>
      <div>
        {FEATURE_GUIDE_PAGE_DATA.map((page) => (
          <Container
            key={page.id}
            className="walkthrough-pages container-sm bg-white mt-5 position-relative"
            style={{ maxWidth: '1000px', height: '1000px' }}
          >
            <Row>
              <Col sm={8}>
                <h1 className="blue-color">{page.heading}</h1>
              </Col>
              <Col sm={4} className="text-end">
                <img
                  src={KestrelProIcon}
                  alt="Kestrel Pro Icon"
                  height="68px"
                  style={{
                    marginTop: '-2px',
                    boxShadow: 'none',
                    height: '68px',
                  }}
                />
              </Col>
            </Row>
            <p>{page.paragraph}</p>
            <Row>
              {page.subSections.map((subSection) => (
                <Col
                  sm={6}
                  key={subSection}
                  className="mt-4 text-center ms-3 mb-4"
                  style={{ width: '400px' }}
                >
                  <h1 style={{ color: '#dba200' }}>
                    <subSection.icon />
                  </h1>
                  <h4>{subSection.title}</h4>
                  <p className="text-secondary">{subSection.description}</p>
                </Col>
              ))}
            </Row>
            <div className="page-number">1</div>
          </Container>
        ))}

        {FEATURES_PAGE_DATA.map((page, index) => (
          <Container
            key={page.id}
            className="walkthrough-pages container-sm bg-white mt-5 position-relative"
            style={{ maxWidth: '1000px', height: '1000px' }}
          >
            <Row>
              <Col sm={6}>
                <h1 className="blue-color">{page.heading}</h1>
              </Col>
              <Col sm={6} className="text-end">
                <img
                  src={KestrelProIcon}
                  alt="Kestrel Pro Icon"
                  height="68px"
                  style={{
                    marginTop: '-2px',
                    boxShadow: 'none',
                    height: '68px',
                  }}
                />
              </Col>
            </Row>

            {page.sections && (
              <div>
                {page.sections.map((section) => (
                  <div key={section}>
                    <h5 className="fw-semibold">{section.title}</h5>

                    <ul>
                      {section.items.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
            <div className="page-number">{index + 2}</div>
          </Container>
        ))}

        {SNAPSHOT_PAGE_DATA.map((page, index) => (
          <Container
            key={page.id}
            className="walkthrough-pages container-sm bg-white mt-5 position-relative"
            style={{ maxWidth: '1000px', height: '1000px' }}
          >
            <Row>
              <Col sm={8}>
                <h1 className="blue-color">{page.heading}</h1>
              </Col>
              <Col sm={4} className="text-end">
                <img
                  src={KestrelProIcon}
                  alt="Kestrel Pro Icon"
                  height="68px"
                  style={{
                    marginTop: '-2px',
                    boxShadow: 'none',
                    height: '68px',
                  }}
                />
              </Col>
            </Row>

            <p>{page.paragraph}</p>
            {page.heading === 'Reports' && (
              <p>
                <b>{page.subHeading} - </b>
                {page.subParaWithHeding}
              </p>
            )}
            {page.heading === 'Ticketing System' && (
              <p>
                <b>{page.subHeading} - </b>
                {page.subParaWithHeding}
              </p>
            )}
            <p>{page.subPara}</p>
            <p>
              {page.subParaSection && (
                <ul>
                  {page.subParaSection.map((subSection) => (
                    <li key={subSection}>{subSection}</li>
                  ))}
                </ul>
              )}
            </p>
            <p>{page.subPara2}</p>
            <p>
              {page.subParaSection2 && (
                <ul>
                  {page.subParaSection2.map((subSection) => (
                    <li key={subSection}>{subSection}</li>
                  ))}
                </ul>
              )}
            </p>
            {page.subSections && (
              <ul>
                {page.subSections.map((subSection) => (
                  <li key={subSection}>{subSection}</li>
                ))}
              </ul>
            )}
            {page.imgSrc && (
              <div className="left-wrap position-relative">
                <img
                  alt={`${page.heading.toLowerCase().replace(' ', '-')}-shape`}
                  src={page.imgSrc}
                  className="img-fluid shape-img"
                />
              </div>
            )}
            <p>{page.subParagraph}</p>
            <div className="page-number">{index + 3}</div>
          </Container>
        ))}
      </div>
      {isVisible && (
        <Button
          onClick={topFunction}
          className="me-3 shadow-lg rounded-circle scroll-to-top-btn"
          id="myBtn"
          title="Go to top"
        >
          <FaArrowUp size={20} color="#0074C9" />
        </Button>
      )}
    </>
  );
};

export default UserPanelWalkThrough;
