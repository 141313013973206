import Loader from 'components/Loader';
import HelpAndSupport from 'pages/HelpAndSupport';
import FAQs from 'pages/HelpAndSupport/components/FAQs';
import HelpAndSupportMainPage from 'pages/HelpAndSupport/components/HelpAndSupportMainPage';
import LinuxWalkthrough from 'pages/HelpAndSupport/components/LinuxWalkthrough';
import MacWalkThrough from 'pages/HelpAndSupport/components/MacWalkthrough';
import MobileToolWalkThrough from 'pages/HelpAndSupport/components/MobileToolWalkThrough';
import UserPanelWalkThrough from 'pages/HelpAndSupport/components/UserPanelWalkThrough';
import WindowsWalkthrough from 'pages/HelpAndSupport/components/WindowsWalkthrough';
import ForgotPassword from 'pages/Login/components/ForgotPassword';
import ResetPassword from 'pages/Login/components/ForgotPassword/components/ResetPassword';
import React, { lazy } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
// import LoginSuccess from '../pages/LoginSuccess';

const AppContainers = () => {
  const routeApp = import('../pages/MainPage');
  const routeLogin = import('../pages/Login');
  const routeLoginSuccess = import('../pages/LoginSuccess');

  const Login = lazy(() => routeLogin);
  const App = lazy(() => routeApp);
  const LoginSuccess = lazy(() => routeLoginSuccess);

  return (
    <div className="App">
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Outlet />}>
            <Route path="/login" element={<Login />} />
            <Route exact path="/loginSuccess" element={<LoginSuccess />} />
            <Route path="*" element={<App />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
          </Route>
          <Route element={<HelpAndSupport />}>
            <Route
              path="/help-and-support"
              element={<HelpAndSupportMainPage />}
            />
            <Route
              path="/help-and-support/application-walk-through"
              element={<UserPanelWalkThrough />}
            />
            <Route
              path="/help-and-support/mac-walk-through"
              element={<MacWalkThrough />}
            />
            <Route
              path="/help-and-support/windows-walk-through"
              element={<WindowsWalkthrough />}
            />
            <Route
              path="/help-and-support/linux-walk-through"
              element={<LinuxWalkthrough />}
            />
            <Route
              path="/help-and-support/mobile-time-tracker-walk-through"
              element={<MobileToolWalkThrough />}
            />
            <Route path="/help-and-support/FAQs" element={<FAQs />} />
          </Route>
        </Routes>
      </React.Suspense>
    </div>
  );
};

export default AppContainers;
