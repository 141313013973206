import React from 'react';

import { Form, Field } from 'formik';
import { Button, FormGroup } from 'reactstrap';
import FormikInputField from 'components/Fields/FormikInputField';
import { useNavigate } from 'react-router-dom';

const LoginForm = (props) => {
  const { handleSubmit, isSubmitting } = props;
  const navigate = useNavigate();
  return (
    <div className="mt-5">
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            type="email"
            name="email"
            component={FormikInputField}
            label="Email"
            placeholder="Email"
          />
        </FormGroup>
        <FormGroup>
          <Field
            type="password"
            name="password"
            component={FormikInputField}
            label="Password"
            placeholder="Password"
          />
        </FormGroup>
        <div className=" d-flex align-items-end justify-content-end">
          <Button
            color="link"
            className="p-0 text-decoration-none fw-normal f-14"
            style={{
              color: '#dba200',
            }}
            onClick={() => {
              navigate('/forgotPassword');
            }}
          >
            Forgot Password
          </Button>
        </div>

        <Button
          fullWidth
          type="submit"
          color="warning"
          block
          // disabled={isSubmitting}
        >
          Login
        </Button>
      </Form>
    </div>
  );
};

export default LoginForm;
