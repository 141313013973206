import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Select from 'react-select';
import { useMutationContext } from 'contexts/IndustrySector';
import { USER_DATA } from 'constants/constants';

const IndustrySector = ({ modalIsOpen, openModal, setModalIsOpen }) => {
  const { t } = useTranslation();
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const userData = JSON.parse(localStorage.getItem(USER_DATA)) || {};

  const { performITMutation, setIndustrySectorState } = useMutationContext();

  const [selectedLanguage, setSelectedLanguage] = useState(
    userData?.industrySector || localStorage.getItem('industrySector')
  );

  useEffect(() => {
    if (userData?.industrySector) {
      setSelectedLanguage(userData?.industrySector);
    }
  }, [userData?.industrySector]);

  const changeLanguage = async (iT) => {
    setSelectedLanguage(iT);
    setIndustrySectorState(iT);
  };

  const handleLanguageChange = (selectedOption) => {
    changeLanguage(selectedOption?.value);
    performITMutation(selectedOption?.value);
  };

  const IndustrySectors = [
    { value: 'itServices', label: 'IT Services' },
    { value: 'itProducts', label: 'IT Products' },
  ];

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        toggle={openModal}
        centered
        style={{ height: '70vh !important', width: '30%' }}
      >
        <ModalHeader toggle={closeModal}>
          {t('Select Industry Sector')}
        </ModalHeader>
        <ModalBody style={{ textAlign: 'center', paddingBottom: '50px' }}>
          <Select
            classNamePrefix="react-select"
            value={IndustrySectors.find(
              (option) => option.value === selectedLanguage
            )}
            onChange={handleLanguageChange}
            options={IndustrySectors}
            // defaultValue={IndustrySectors.find(
            //   (option) =>
            //     option.value === industrySectorType || selectedLanguage
            // )}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default IndustrySector;
