import * as Yup from 'yup';

const validationSchema = () => {
  const schema = {
    email: Yup.string('')
      .nullable()
      .required('Email is required')
      .email('Please enter a valid email')
      .trim('Please enter valid email'),
    password: Yup.string().required('Password is required'),
  };

  return Yup.object().shape(schema);
};

export default validationSchema;
