import * as actionTypes from './LoginActions';

export const userLogout = (payload) => {
  return {
    type: actionTypes.USER_LOGOUT,
    payload,
  };
};

export default {
  userLogout,
};
