/* eslint-disable react/jsx-no-useless-fragment */
import { useMutation } from '@apollo/client';
import React, { createContext, useContext, useState } from 'react';
import { authContext } from 'contexts/AuthContext/AuthContext';
import { LANGUAGE_SWITCHER } from 'contexts/AppDetailsGraphQL';
import { USER_DATA, USER_TOKEN } from 'constants/constants';

export const MutationContext = createContext();

const PreferredLanguage = ({ children }) => {
  const [langState, setlangState] = useState();

  const { auth } = useContext(authContext);

  const userDataLang =
    auth?.data?.data?.preferredLanguage || auth?.data?.preferredLanguage;
  const [switchLanguage, { error, loading, data }] =
    useMutation(LANGUAGE_SWITCHER);

  const performMutation = async (preferredLanguage) => {
    // setlangState(preferredLanguage);
    try {
      const { data } = await switchLanguage({
        variables: {
          preferredLanguage,
        },
      });

      localStorage.setItem('preferredLanguage', preferredLanguage);
      localStorage.setItem(USER_TOKEN, data?.switchLanguage?.token);
      const userData = JSON.parse(localStorage.getItem(USER_DATA)) || {};

      userData.token = data?.switchLanguage?.token;
      userData.preferredLanguage =
        data?.switchLanguage?.preferredLanguage || userDataLang;

      localStorage.setItem(USER_DATA, JSON.stringify(userData));
    } catch (err) {
      console.error('Error switching language:', error);
    }
  };

  return (
    <MutationContext.Provider
      value={{
        error,
        loading,
        performMutation,
        data,
        setlangState,
        langState,
      }}
    >
      {children}
    </MutationContext.Provider>
  );
};
export default PreferredLanguage;
export const useMutationContext = () => useContext(MutationContext);
