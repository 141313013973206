import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import KestrelProIcon from 'assets/image/KestrelPro.svg';
import MacIntel from 'assets/image/Mac - Intel Chip.png';
import MacM1 from 'assets/image/Mac - M1 Chip.png';
import { FaArrowUp } from 'react-icons/fa';

const MacWalkThrough = () => {
  const [isVisible, setIsVisible] = useState(false);

  const pagesData = [
    {
      id: 1,
      heading: 'MAC Intel',
      paragraph: '',
      subSections: [
        'Download the .dmgfile and drag and drop Kestrel Pro in the application.',
        'Please open it and go to system (preferences/settings).',
        'Go to the security and privacy tab.',
        'Go to general, and click on open anyway.',
        'Go to accessibility and enable JAVA/BASH.',
        'Login into the tool with custom login/SSO.',
        'Select the preassigned project from the list and add the task to the project.',
        'Select the task, and the tool is all set to use.',
      ],
      subPara: 'Screenshots Permission Flow',
      subSections2: [
        'Let one screenshot be captured.',
        'You will get a pop-up as soon as Kestrel Pro captures the screenshot.',
        'Click on the system preferences, and select screen recording.',
        'Enable JAVA/BASH and Kestrel Pro; if it is not present,click to add Kestrel Pro.',
        'You will be redirected to quit and open the application.',
      ],
    },

    {
      id: 2,
      heading: 'MAC M1',
      paragraph: '',
      subSections: [
        'Download the ZIP file, and extract it and drag and drop Kestrel Pro into the application.',
        'Open the application, and go to system preferences/settings.',
        'Go to Security and Privacy Tab.',
        'Go to General, and select the open anyway option for Kestrel Pro.',
        'Go to accessibility and enable JAVA/BASH.',
        'Login into the tool with Custom login/SSO.',
        'Select the preassigned project from the list and add the task to the project.',
        'Select the task, and the tool is all set to use.,',
      ],
      subPara: 'Screenshots Permission Flow',
      subSections2: [
        'Let one screenshot be captured.',
        'You will get a pop-up as soon as Kestrel Pro captures the screenshot.',
        'Click on the system preferences, and select screen recording.',
        'Enable JAVA/BASH and Kestrel Pro; if it is not present, click to add Kestrel Pro.',
        'You will be redirected to quit and open the application.',
      ],
    },
  ];
  const handleScrollToTop = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  // Add event listener for scrolling
  useEffect(() => {
    topFunction();
    window.addEventListener('scroll', handleScrollToTop);
    return () => {
      window.removeEventListener('scroll', handleScrollToTop);
    };
  }, []);

  return (
    <>
      <div>
        {pagesData.map((page, index) => (
          <Container
            key={page.id}
            className="walkthrough-pages container-sm bg-white mt-5 position-relative"
            style={{ maxWidth: '1000px', height: '1000px' }}
          >
            <Row>
              <Col sm={6}>
                <h1 className="blue-color">{page.heading}</h1>
              </Col>
              <Col sm={6} className="text-end">
                <img
                  src={KestrelProIcon}
                  alt="Kestrel Pro Icon"
                  height="68px"
                  style={{
                    marginTop: '-2px',
                    boxShadow: 'none',
                    height: '68px',
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={8}>
                <p>{page.paragraph}</p>

                {page.subSections && (
                  <ul>
                    {page.subSections.map((subSection, index) => (
                      <>
                        <li
                          key={subSection}
                          style={{ listStyleType: 'decimal' }}
                        >
                          <p className="ms-1">{subSection}</p>
                        </li>
                      </>
                    ))}
                  </ul>
                )}
                <p className="fw-semibold ms-3 f-20 mt-4 pt-2">
                  {page.subPara}
                </p>
                {page.subSections2 && (
                  <ul>
                    {page.subSections2.map((subSection, index) => (
                      <li key={subSection} style={{ listStyleType: 'decimal' }}>
                        <p className="ms-1">{subSection}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </Col>

              <Col sm={4} className="text-end">
                <img
                  src={page.heading === 'MAC Intel' ? MacIntel : MacM1}
                  alt="Kestrel Pro Icon"
                  style={{
                    boxShadow: 'none',
                    height: '200px',
                    marginTop: '0px',
                    // marginLeft: '60px',
                  }}
                />
              </Col>
            </Row>
            <div className="page-number">{index + 1}</div>
          </Container>
        ))}
      </div>
      {isVisible && (
        <Button
          onClick={topFunction}
          className="me-3 shadow-lg rounded-circle scroll-to-top-btn"
          id="myBtn"
          title="Go to top"
        >
          <FaArrowUp size={20} color="#0074C9" />
        </Button>
      )}
    </>
  );
};

export default MacWalkThrough;
