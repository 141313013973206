import DashboardSnap from 'assets/image/dashboard.png';
import EmployeeSnap from 'assets/image/Employee.png';
import TeamSnap from 'assets/image/team.png';
import ProjectSnap from 'assets/image/project.png';
import ProjectStatusSnap from 'assets/image/ProjectStatus.png';
import TimesheetSnap from 'assets/image/timesheet.png';
import ScreenshotSnap from 'assets/image/screenshot.png';
import ViewApprovedHours from 'assets/image/viewApprovedHours.png';
import UtilizationReportSnap from 'assets/image/utilizationReport.png';
import AllocationVsActualSnap from 'assets/image/allocationActual.png';
import TeamAvailablitySnap from 'assets/image/teamAvaliblity.png';
import TimesheetDefaulterSnap from 'assets/image/timesheetDefaulter.png';
import TicketingSystemSnap from 'assets/image/ticketingSystem.png';
import overAllReportSnap from 'assets/image/ResourceReport.png';
import projectResourcesSnap from 'assets/image/project1.png';
import { PiStarOfDavid } from 'react-icons/pi';
import { BsGraphUpArrow } from 'react-icons/bs';
import { TbDeviceImac } from 'react-icons/tb';
// import { LuCheckCircle } from 'react-icons/lu';
import { LiaCheckCircle } from "react-icons/lia";
import LoginSnap from 'assets/image/login.png';
import TimesheetSummary from 'assets/image/timesheetSummary.png';

export const SNAPSHOT_PAGE_DATA = [
  {
    id: 1,
    heading: 'Login Page',
    paragraph:
      'Using security credentials user can log in with a single sign-on (SSO).',
    subParagraph:
      'The next page has the navigation to various sections of the product – Dashboard, Employees, Projects, Timesheets, Screenshots, Invoices, Reports, Tickets.',
    imgSrc: LoginSnap,
  },
  {
    id: 2,
    heading: 'Dashboard',
    paragraph:
      'It is the complete summary of the time utilization of the user in the particular project they are working with. It also helps in comparing allocated hours and actual utilization along with measuring the performance of the individual through the performance section.',
    imgSrc: DashboardSnap,
  },
  {
    id: 3,
    heading: 'Employees',
    paragraph:
      'The interface comprises employee details such as name, employee ID, years of experience, skills, team, project, employee role, status, mentor, and lead.',
    subPara: [
      'It consists of a search panel where the user can search for employee details that can be identified based on available filters – Status (Active/Inactive), Team (Team Name), Skill (Expertise), Mentor (Works with which Mentor), and Team Lead (Reporting Lead).',
    ],
    imgSrc: EmployeeSnap,
  },
  {
    id: 4,
    heading: 'Employees',
    paragraph:
      'This section is accessible only by Super Admin, which helps them add employees to different teams.',
    subPara: 'The section has the following functionalities –',
    subParaSection: [
      'Team utilization report',
      'Allows screenshot deletion (A specific number of screenshots can be deleted)',
      'Capture team screenshot',
      'Team using timesheet tool',
      'Update defaulter list',
    ],
    imgSrc: TeamSnap,
  },
  {
    id: 5,
    heading: 'Projects',
    paragraph: 'The section is accessible by the Super admin/Sales team.',
    subPara: 'They are capable of –',
    subPara2: 'The Project Manager can –',
    subParaSection: [
      'Adding the project with details and assigning a project manager for the same.',
      'Update billing for the project (Fixed Price/ Hourly)',
    ],
    subParaSection2: [
      'Add employees',
      'Specify team-wise planned hours; an employee is billable, and the project manager can update whether another resource will shadow the resource.',
      'The invoice will have the details of whether the employee is billable.',
    ],
    imgSrc: projectResourcesSnap,
  },
  {
    id: 6,
    heading: 'Projects',
    paragraph: 'The generic users (employees) can -',
    subParaSection: [
      'View all the projects with details – Project Name, Expected start/end date, Project Manager, Sales Lead, categorize it as revenue generating (Y/N), resources assigned to the project, and if the resource is Billable/Non-Billable, with the assignment of resource availability.',
      'Filter projects on the following parameters – Project manager, fixed price (Y/N), Status (Not started, in progress, completed, on hold, or canceled)',
    ],
    imgSrc: ProjectSnap,
  },
  {
    id: 7,
    heading: 'Timesheet',
    paragraph: 'Users can perform following functions - ',

    paragraph1: 'Employees can use it for automatic and manual entry ',
    subParaSection: [
      'They can approve manual entries added by employees.The reason must be clarified in case any manual entry needs approval.',
      'They can see timesheets for all the users. Employees can use it for automatic and manual entry - ',
    ],
    imgSrc: TimesheetSnap,

    subPara2: 'Employees can use it for automatic and manual entry - ',

    subParaSection2: [
      'The employees need to install a desktop-based app and select the tasks from already uploaded task entries displayed over a pop-up',
      'Employees have to add projects/tasks and the reason for manual entry',
      'The employee can monitor timesheets for their respective projects',
    ],
  },
  {
    id: 8,
    heading: 'Screenshot',
    paragraph:
      'Users are able to monitor, screenshots of all employees for any given date.Employees can submit screenshots at a particular interval, which the super admin can set.',
    subPara:
      'They can delete up to X screenshots set by the super admin after X screenshots 10 minutes will be deleted from the total time captured for screenshots deleted.',
    imgSrc: ScreenshotSnap,
  },
  {
    id: 9,
    heading: 'Accounting – Approve Hours for Invoicing',
    subParaSection: [
      'For hourly-based projects, the users can approve hours of resources for invoicing; after approving the hours, they can see approved hours under the "View approved hours" section. The approved hours can be filtered by project name.',
      'For fixed-price projects, they can track payment milestones filtered by project name.',
    ],
    imgSrc: ViewApprovedHours,
  },
  {
    id: 9,
    heading: 'Accounting – View Approved Hours',
    subParaSection: [
      'In this section the user can view approved hours and this is used to generate invoice for a project post the user approves the hours of billable resources. You will be able to see the list of projects here. You can select the project you want to download the invoice for. Also, you can now see the complete invoice summary of the project and you can download invoice using the download button.',
    ],
    imgSrc: ViewApprovedHours,
  },
  {
    id: 9,
    heading: 'Accounting – View Payment Milestone',
    subParaSection: [
      'This is the third section of KestrelPro Accounting. Here the user can select the project and date range, view the details along with added date and amount for each milestone.',
    ],
    imgSrc: ViewApprovedHours,
  },
  {
    id: 10,
    heading: 'Reports',
    subHeading: 'Utilization Report',
    paragraph:
      'Analytics reports are made using PowerBI. The user can view the total active employees, billable and non-billable hours at  the team level, drilled down to each resource. The user can view the following reports -',
    subParaWithHeding:
      'Provides insights ofBillable/Non-Billable hours, total time, percentage of billedeffort(billable hours/total time), average billable hours, totalcapacity, and billed capacity percentage(billable hours/total capacity); divided as per teams. The user can filter the reportbased on the project, team, employee status, and billability.',
    imgSrc: UtilizationReportSnap,
  },
  {
    id: 11,
    heading: 'Reports',
    subHeading: 'Allocation vs. Actual',
    subParaWithHeding:
      'Displays the difference between the allocated hours vs. actual hours given to a specific project by a specific employee. The report can be filtered based on date, project name, team, and employee.',
    imgSrc: AllocationVsActualSnap,
  },
  {
    id: 12,
    heading: 'Reports',
    subHeading: 'Team Availability',
    subParaWithHeding:
      'Helps identify the availability of resources at all times for a particular time duration. It is divided for all the teams based on "Available," "Partially Available," and "Not Available." The filters available for the report are - Date, Project, Team, Employee, and Experience range (in years).',
    imgSrc: TeamAvailablitySnap,
  },
  {
    id: 13,
    heading: 'Reports',
    subHeading: 'Timesheet Summary',
    subParaWithHeding:
      'Displays all employees total timesheet hours and total fixed billing hours.',
    subPara:
      'The filters available are – Date, Project, Employee, and Billability.',
    imgSrc: TimesheetSummary,
  },
  {
    id: 14,
    heading: 'Reports',
    subHeading: 'Timesheet Defaulters ',
    subParaWithHeding:
      'Lists of employees with a difference between allocated vs. actual hours the employee has worked. The report can be filtered based on Date and Minimum Expected hours.',
    subPara:
      'The platform can automatically trigger an email to the HR team with the name of the defaulters.',
    imgSrc: TimesheetDefaulterSnap,
  },
  {
    id: 15,
    heading: 'Reports',
    subHeading: 'Project Status ',
    subParaWithHeding:
      'Provides insights based on the timeline of each project based on the following filters – Planned Hours, Timesheet Hours, and difference.',
    subPara:
      'The report can be filtered using the following parameters - Date, Project.',
    imgSrc: ProjectStatusSnap,
  },
  {
    id: 16,
    heading: 'Overall Interactive Report',
    paragraph:
      'Here the user can see a complete view of allocation and utilization at individual unit level. This helps in identifying trends and further facilitate decision making.',
    imgSrc: overAllReportSnap,
  },
  {
    id: 17,
    heading: 'Ticketing System',
    subHeading: 'Tickets',
    subParaWithHeding:
      'Accessible by Admin team and employees, where users can raise and receive tickets and admin can monitor the tickets raised at a given point.',
    imgSrc: TicketingSystemSnap,
  },
];

export const FEATURE_GUIDE_PAGE_DATA = [
  {
    id: 1,
    heading: 'KestrelPro Feature Guide',
    paragraph:
      'Built for businesses of all sizes, KestrelPro is the key to unlock potential of all the departments and align them strategically. Its core functionality is real-time data aggregation, analytics, and visualization.',
    subSections: [
      {
        title: 'Enhanced workforce productivity and accountability',
        description: 'Identify areas of improvement in your workforce',
        icon: LiaCheckCircle,
      },
      {
        title: 'Informed decision-making with workplace analytics',
        description: 'Measure the effectiveness of your new initiatives',
        icon: BsGraphUpArrow,
      },
      {
        title: 'Complete transparency into Operations',
        description: 'Efficiently monitoring employees’ work status',
        icon: TbDeviceImac,
      },
      {
        title: 'Optimum resource management',
        description: 'Skill-based resource allocation and planning',
        icon: PiStarOfDavid,
      },
    ],
  },
];

export const FEATURES_PAGE_DATA = [
  {
    id: 1,
    heading: 'Features',
    sections: [
      {
        title: 'Efficient Finances',
        items: [
          'Automated invoicing for hourly projects',
          'Real-time notifications over email for every milestone',
        ],
      },
      {
        title: 'Informed Decisions',
        items: [
          'Real-time analytics reports on Resource Utilization',
          'Analytics report on time utilized by resources against a project',
          'Detailed report on team availability',
        ],
      },
      {
        title: 'Effective Project Management',
        items: [
          'Project module to add and manage project and payment milestones',
          'Timesheet for tracking individual tasks and projects',
        ],
      },
      {
        title: 'Simplified Admin Processes',
        items: [
          'Automated ticketing system',
          'Real-time management of all the tickets raised',
        ],
      },
      {
        title: 'Increased ROI with efficient Sales',
        items: [
          'Available resource and skillset data on fingertips to pitch for new projects',
          'No communication gaps between the teams regarding project budgeting and payments',
        ],
      },
      {
        title: 'Streamlined HR Operations',
        items: [
          'One platform for the HR functions - managing employee records, performance tracking',
          'Intelligent skill matching for effective recruitments',
        ],
      },
      {
        title: 'Technical Features',
        items: [
          'Enterprise-grade data security',
          'PowerBI-based easily accessible reports',
          'Available on mac, windows, and Linux',
          'Role-based features',
          'Configured with work email for status, defaulters updates',
        ],
      },
    ],
  },
];
export const FAQ_DATA = [
  {
    id: 1,
    heading: 'FAQ',
    faqs: [
      {
        id: 1,
        question: 'What is KestrelPro?',
        answer:
          'KestrelPro is a comprehensive business management software designed to enhance performance and efficiency across various departments such as sales, finance, project management, HR, and administration.',
      },
      {
        id: 2,
        question: 'What features does KestrelPro offer?',
        answer:
          'KestrelPro offers a wide range of features including real-time data aggregation, analytics, visualization, project management, resource management, automated invoicing, money leakage proofing, and sales optimization.',
      },
      {
        id: 3,
        question: 'How can KestrelPro benefit my business?',
        answer:
          'KestrelPro helps streamline operations, improve decision-making through data-driven insights, enhance productivity, and ensure better resource management across all departments.',
      },
      {
        id: 4,
        question: 'Is KestrelPro suitable for small businesses?',
        answer:
          'Yes, KestrelPro is designed to be scalable and can be tailored to meet the needs of all small, mid-size and large-scale enterprises.',
      },
      {
        id: 5,
        question: 'How secure is my data with KestrelPro?',
        answer:
          'KestrelPro employs advanced security measures, including encryption and regular security audits, to ensure that your data is protected at all times.',
      },
      {
        id: 6,
        question: 'Can KestrelPro integrate with other software/tools?',
        answer:
          'Yes, KestrelPro supports integration with various third-party applications and software to provide a seamless workflow and enhanced functionality.',
      },
      {
        id: 7,
        question: 'What kind of customer support does KestrelPro offer?',
        answer:
          'KestrelPro offers 24/7 customer support through various channels including email, phone, and live chat to assist you with any issues or queries.',
      },
      {
        id: 8,
        question: 'Is there a free trial available for KestrelPro?',
        answer:
          'Yes, KestrelPro offers a free trial period so you can explore its features and see how it fits your business needs before making a commitment.',
      },
      {
        id: 9,
        question: 'How do I get started with KestrelPro?',
        answer:
          'You can get started by seeking a demo from our website. Once the demo is done, you can request for trial via emailing on info@kestrelpro.ai. Also, you can ask for customizations to suit your business requirements and start using it immediately.',
      },
      {
        id: 10,
        question: 'Are there any training resources available for KestrelPro?',
        answer:
          'Yes, KestrelPro provides comprehensive training resources including installation guides for all devices and feature guides too to help you and your team get the most out of the software.',
      },
    ],
  },
];
